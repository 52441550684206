/* radiobuttons */
$(document).on('change', '.__radiobuttons input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radiobuttons')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radiobuttons input:checked')
    .parent()
    .addClass('__checked');

/* checkboxes */
$(document).on('change', '.__checkbox input', function() {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');

/* focus styling */
$(document).ready(function() {
    $('.field_wrap.__radiobuttons input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__radiobuttons input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input').focus(function() {
        $(this)
            .closest('label')
            .addClass('__focused');
    });
    $('.field_wrap.__checkbox input').blur(function() {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
});


// Date picker
    flatpickr(".DatePicker",{
        altInput: true,
        altFormat: "j F y"
    });

// Time picker
    flatpickr(".TimePicker",{
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });

// Date and time picker
    flatpickr(".BothPicker",{
        enableTime: true,
        dateFormat: "Y-m-d H:i",
    });

// select2
    $('.__select select').each(function() {
        var selectId = $(this).parent();
        $(this).select2({
            containerCssClass: 'select2-css',
            dropdownParent: selectId,
            minimumResultsForSearch: Infinity,
        })
    });

// Select multiple options
    $('.__multiselect select').each(function() {
        var selectId = $(this).parent();
        $(this).select2({
            containerCssClass: 'select2-css',
            multiple: true,
            dropdownParent: selectId
        })
    });
