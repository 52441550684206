// Chart.js
$('.chart-score canvas').each(function() {
    var ctx = $(this).get(0);
    var dataValue = $(this).data('value');
    var dataEmpty = 100 - dataValue;

    if ($(this).data('color')) {
        var dataColor = $(this).data('color');
    } else {
        var dataColor = '#8FFF74';
    }

    var chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
            datasets: [
                {
                    data: [dataValue, dataEmpty],
                    backgroundColor: [dataColor, 'rgba(0,0,0,0)'],
                    borderWidth: 0,
                }
            ]
        },
        options: {
            cutoutPercentage: '97',
            aspectRatio: 1,
            legend: {
                display: false
            },
            plugins: {
                legend: {
                    display: false,
                }
            },
            tooltips: {
                enabled: false
            }
        }
    });
});

// CSS Charts
$('.chart-progress-chart').each(function() {
    var $this = $(this);
    var barFill = $this.data('value');
    setTimeout(function() {
        $this
            .find('span.chart-progress-chart-bar')
            .css('width', barFill + '%');
    }, 300);
});
