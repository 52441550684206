// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
// WebFont.load({
//     google: { // add &display=swap to end of every font family
//         families: ['Roboto:400,500,700&display=swap']
//     },
//     //,
//     // typekit: {
//     // id: 'XXXX'
//     // },
//     active: function() { // helps stop flash of unstyled text
//         sessionStorage.fontsLoaded = true
//     }
// });

// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('objectFitPolyfill/dist/objectFitPolyfill.min');
require('select2/dist/js/select2.full.min');
require('slick-slider/slick/slick.min');
// require('chart.js/dist/chart.min');
require('jquery-mousewheel/jquery.mousewheel');
import PerfectScrollbar from 'perfect-scrollbar';
import Dragdealer from 'dragdealer/src/dragdealer';

//Load Modules
require('./modules/menu');
require('./modules/charts');
// require('./modules/accordion');
require('./modules/animatein');
// require('./modules/bannerVideo');
require('./modules/formInputs');
// require('./modules/localVideoBlock');
// require('./modules/modal');
// require('./modules/scrollTo');
// require('./modules/shareSocial');
// require('./modules/sideMenu');
// require('./modules/tabs');


$( document ).ready(function() {
    $('.dropdownButton').click(function(){
        $(this).siblings('.dropdownList').slideToggle();
        $(this).siblings('.dropdownList').toggleClass('__active');
        $(this).toggleClass('__active');
    })

    $(".slider-items").each(function() {
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.slider').find('.sliderNextButton'),
            mobileFirst: true,
            responsive: [
                {
                    breakpoint:992,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    })
});

var drag;
$(document).ready(function(){
    var count = $(".slider-items-withbar .slideritem").length;
    var slick = $(".slider-items-withbar").each(function() {
        var thisElement = $(this);
        var dragbar = $(this).attr('data-dragbarid');
        thisElement.slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '20px',
            slidesToScroll: 1,
            touchThreshold: 20,
            prevArrow: $(this).closest('.slider').find('.sliderPrevButton'),
            nextArrow: $(this).closest('.slider').find('.sliderNextButton'),
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4
                    }
                },
            ]
        });

        $(this).on('afterChange', function(event, slick, currentSlide){
            drag.setStep($(this).slick('slickCurrentSlide')+1)
        });

        drag = new Dragdealer(dragbar, {
            x: 0,
            steps: count,
            callback : function(x,y){
                var currentSlide = drag.getStep()[0]-1 ;
                if($('.slider-items-withbar').slick('slickCurrentSlide') != currentSlide){
                    $('.slider-items-withbar').slick('slickGoTo',drag.getStep()[0]-1);
                }
                // $("#dragdealerbar .handle").width(drag.getStepWidth() * drag.getStep()[0]-1)
            }
        });
    });

    if ($('#TableScroller').length) {
        $("#TableWrap").mousewheel(function(event, change) {
            this.scrollLeft -= (change * 50);
            event.preventDefault();
        });
        // Table scrollbar functionality
        var ps = new PerfectScrollbar(document.getElementById('TableWrap'));

        var maxWidth = $('#Table').outerWidth() - ($('#TableWrap').outerWidth());

        $('#TableScroller').attr('max', maxWidth);
        $('#TableScroller').on('input', function () {
            var value = this.value;
            $('#TableWrap').scrollLeft(value);
        });
        $('#TableScroller').on('change', function () {
            var value = this.value;
            $('#TableWrap').scrollLeft(value);
        });

        $('#TableWrap').scroll(function () {
            $('#TableScroller').val($('#TableWrap').scrollLeft());
        })
    }
});

$(window).on("load",function(){
    $('.__animate').animateIn({
        offset: 100,
        modifier: '__animatein'
    });
    $('.__animatefade').animateIn({
        offset: 100,
        modifier: '__animatein'
    });
});
